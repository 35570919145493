<template>
	<div class="shouhuoaddress">
		<div v-if="address1==null||address1.length==0" class="noaddress">
			<div>
				<div
					style="height: 1.875rem;font-weight: bold;font-size: 1.875rem;margin-bottom: 3.25rem;color: #2B9BB3;">
					您还没有地址,请添加新地址</div>
				<el-button class="btnAddNewAddress" @click="dialogFormVisible = true" icon="el-icon-plus">添加新地址
				</el-button>
			</div>
		</div>
		<div class="selectAddressCon" v-if="address1!=null&&address1.length>0">
			<div class="addresstitle"><span>选择收货地址</span>
				<el-button class="btnAddNewAddress" @click="dialogFormVisible = true" icon="el-icon-plus">添加新地址
				</el-button>
			</div>
			<div class="addresscon">
				<div class="alreadyAddress" v-for="(item,index) in address1">
					<div align="left" style="margin-bottom: 0.3125rem;">{{item.userName}} &nbsp;{{item.phone}}</div>
					<div align="left" style="margin-bottom: 0.3125rem;">{{item.cityInfo}}{{item.address}} &nbsp;</div>
					<div align="left">
						<el-radio-group v-model="radio" @change="setDefaultChange">
							<el-radio v-if="item.isdefault==1" :label="item.id">已设为默认</el-radio>
							<el-radio v-if="item.isdefault==0" :label="item.id">设为默认</el-radio>
						</el-radio-group>&nbsp;&nbsp;
						<a href="#" style="font-size: 0.875rem;" @click="delAddress(item.id)">删除</a>&nbsp;&nbsp;
						<a href="#" style="font-size: 0.875rem;" @click="setAddress(item.id)">选择该地址</a>
					</div>
				</div>
			</div>
			<div>
				<button @click="goOrderSix" class="btnorder" style="width: 30%;" type="button">返回</button>
				<button type="button" class="btnorder" @click="nextStep" style="width: 30%;">下一步</button>
			</div>

		</div>

		<!-- 添加地址的dialog -->
		<el-dialog title="收货地址" :visible.sync="dialogFormVisible" width="40%" @close ='resetDialogForms'>
			<el-form :model="ruleForm" label-width="80px" ref="ruleForm" :rules="rules">
				<el-form-item label="收货人" :label-width="formLabelWidth" prop="userName">
					<el-input v-model="ruleForm.userName" autocomplete="off" placeholder="请输入收货人姓名"></el-input>
				</el-form-item>
				<el-form-item label="手机号" :label-width="formLabelWidth" prop="phone">
					<el-input v-model="ruleForm.phone" autocomplete="off" placeholder="请输入手机号"></el-input>
				</el-form-item>
				<el-form-item label="地区" :label-width="formLabelWidth" prop="regionId" required>
					<el-cascader v-model="value" :options="sheng" :props="{ expandTrigger: 'hover' }"
						@change="handleChange"></el-cascader>
					<el-cascader v-model="shiValue" :options="shi" :props="{ expandTrigger: 'hover' }"
						@change="handleChange1"></el-cascader>
					<el-cascader v-model="quValue" :options="qu" :props="{ expandTrigger: 'hover' }"
						@change="handleChange2"></el-cascader>
				</el-form-item>
				<el-form-item label="详细地址" :label-width="formLabelWidth" prop="address">
					<el-input v-model="ruleForm.address" autocomplete="off" placeholder="请输入详细地址"></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="cancel">取 消</el-button>
				<el-button type="primary" @click="submitForm('ruleForm')">添加</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		data: function() {
			return {
				qu: [],
				quValue: [],
				shi: [],
				shiValue: [],
				sheng: [],
				value: [],
				radio: '',
				ruleForm: {
					doctorId: '',
					regionId: "",
					address: '',
					phone: '',
					userName: '',
					oid: '0'
				},
				rules: {
					userName: [{
						required: true,
						message: '请输入收货人姓名',
						trigger: 'blur'
					}],
					phone: [{
						required: true,
						message: '请输入联系方式',
						trigger: 'blur'
					}],
					address: [{
						required: true,
						message: '请输入详细地址',
						trigger: 'blur'
					}],
					regionId: [{
						required: true,
						message: '请输入省市区',
						trigger: 'change'
					}],

				},
				address1: [],
				dialogFormVisible: false,
				formLabelWidth: '120px',
				options: [], //存储选中的省市区
			}
		},
		methods: {

			//修改默认地址
			setDefaultChange(addressId) {
				this.radio = addressId;
				this.$axios.get("client/doctor/setDefaultAddress", {
					params: {
						doctorId: this.ruleForm.doctorId,
						addrId: addressId
					}
				}).then(res => {
					console.log(res);
				}).catch(err => {
					this.$message.error("请联系管理员");
				});
			},
			//查询该医生的所有地址
			getAddressByDoctorId() {
				this.$axios.get("client/doctor/queryDoctorAddress", {
					params: {
						doctorId: this.ruleForm.doctorId
					}
				}).then(res => {
					console.log(res);
					this.address1 = res.data.data.addressList;
					this.radio = res.data.data.defaultId;
				}).catch(err => {
					console.log(err);
					this.$message.error("请联系管理员");
				});
			},

			//添加新地址
			submitForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						alert('submit!');
						this.$axios.post("/client/doctor/addAddress", JSON.stringify(this.ruleForm), {
							headers: {
								"Content-type": "application/json"
							}
						}).then(res => {
							console.log(res);
							if (res.data.code == 200) {
								this.address1 = res.data.data;
								this.initForm();
								this.dialogFormVisible = false;
							}
						}).catch(err => {
							this.$message.error("请联系管理员");
						});
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			//dialog关闭的时候清空表单内容
			initForm() {
				this.ruleForm.address = '';
				this.ruleForm.phone = '';
				this.ruleForm.userName = '';
				this.value = [];
				this.shiValue = [];
				this.quValue = [];
			},
			//删除地址
			delAddress(addrId) {
				this.$axios.get("client/doctor/delAddress", {
					params: {
						addrId: addrId
					}
				}).then(res => {
					if (res.data.code == 200) {
						this.$message.success({
							message: "删除成功",
							duration: 800
						});
						this.getAddressByDoctorId();
					}
				}).catch(err => {
					console.log("请联系管理员");
				});
			},
			//使用已有地址
			setAddress(addrId) {
				this.$axios.get("client/order/addOrderAddress", {
					params: {
						addrId: addrId,
						oid: this.ruleForm.oid
					}
				}).then(res => {
					if(res.data.code==200){
						this.$router.push("/addorder/ordereight");
					}
				}).catch(err => {
					this.$message.error("请联系管理员");
				});
			},
			//查询省市区
			getShengShiQu() {
				this.$axios.get("queryNextLevelRegions", {
					params: {
						pid: 100000
					}
				}).then(res => {
					let a = res.data.data
					for (let i = 0; i < a.length; i++) {
						let obj = {
							"value": a[i].id,
							"label": a[i].name
						};
						this.sheng.push(obj);
					}
				}).catch(err => {
					this.$message.error("请联系管理员");
				});
			},
			//根据省查询市
			handleChange(v) {
				console.log(v);
				console.log(this.value);
				//每次添加市之前先清空数组
				this.shi = [];
				//根据省id查询市
				this.$axios.get("queryNextLevelRegions", {
					params: {
						pid: v[0]
					}
				}).then(res => {
					let a = res.data.data
					for (let i = 0; i < a.length; i++) {
						let obj = {
							"value": a[i].id,
							"label": a[i].name
						};
						this.shi.push(obj);
					}
				}).catch(err => {
					this.$message.error("请联系管理员");
				});
			},
			//根据市查询区
			handleChange1(v) {
				console.log(v);
				console.log(this.value);
				//每次添加区之前先清空数组
				this.qu = [];
				//根据省id查询市
				this.$axios.get("queryNextLevelRegions", {
					params: {
						pid: v[0]
					}
				}).then(res => {
					let a = res.data.data
					for (let i = 0; i < a.length; i++) {
						let obj = {
							"value": a[i].id,
							"label": a[i].name
						};
						this.qu.push(obj);
					}
				}).catch(err => {
					this.$message.error("请联系管理员");
				});
			},
			//选中区之后给ruleForm中的cityInfo赋值
			handleChange2(v) {
				this.ruleForm.regionId = v[0];
			},
			resetDialogForms(){
				this.$refs.ruleForm.resetFields();
			},
			//下一步
			nextStep() {
				this.$router.push("/addorder/ordereight")
			},
			//单击dialog的取消清空form
			cancel() {
				this.initForm();
				this.dialogFormVisible = false;
			},
			//返回
			goOrderSix() {
				this.$router.push("/addorder/ordersix")
			}
		},
		mounted: function() {
			this.$store.commit('setStepIndex', 6);
			//设置默认地址
			sessionStorage.setItem("location", "orderseven")
			//获取登录用户的id
			let id = JSON.parse(sessionStorage.getItem("loginInfo")).id;
			console.log(id);
			this.ruleForm.doctorId=id;
			//获取当前添加的订单oid
			//获取oid
			let oid = sessionStorage.getItem("oid");
			if(oid==null){
				this.$alert("请填写患者信息","提示");
				this.$router.push("/addorder/orderone");
			}else{
				this.ruleForm.oid=oid;
			}
			//获取收货地址
			this.getAddressByDoctorId();
			//获取省市区
			this.getShengShiQu();
		}
	}
</script>

<style scoped="scoped">
	.shouhuoaddress {
		background-image: linear-gradient(to bottom, white, #9BEDEB, #28D9D5);
		height: 100%;
	}

	.shouhuoaddress .el-form-item__label {
		text-align: left !important;
	}

	.btnAddNewAddress {
		border: none;
		background-image: linear-gradient(to right, white, #28D9D5, #2B9BB3);
	}

	.noaddress {
		height: 40%;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	/* 收货地址 */
	.addresstitle {
		font-weight: bolder;
		color: #28B9D5;
		font-size: 1.875rem;
		display: flex;
		justify-content: space-between;
		margin-bottom: 1.25rem;
	}

	/* alreadyAddress */
	.alreadyAddress {
		width: 90%;
		height: 5.25rem;
		border: 0.0625rem solid #28B9D5;
		padding: 0.3125rem;
		margin: 0px auto;
		border-radius: 0.9375rem;
		margin-bottom: 1.25rem;
	}

	/* 放地址的大盒子 */
	.selectAddressCon {
		width: 60%;
		height: 60%;
		margin: 0px auto;
		margin-top: 1.25rem;
		/* border: 1px solid red; */
		overflow-y: auto;
		border-radius: 0.9375rem;
		background-image: linear-gradient(to bottom, white, #F2F2F2);
		box-shadow: 0px 0px 20px gray;
		padding: 0.625rem;
	}

	/* div水平的滚动条隐藏 */
	.selectAddressCon::-webkit-scrollbar {
		display: none;
	}

	/* 按钮样式 */
	.btnorder {
		height: 1.875rem;
		border-radius: 0.625rem;
		background-image: linear-gradient(to right, white, #28D9D5, #2B9BB3);
		border: none;
	}
</style>
